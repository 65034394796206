section.about-intro {
    padding: 250px 0;
    //background: #E8EDF3;
    background: mix($secondary, white, 15%);
    &.has-bg {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center 70%;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0; top: 0; right: 0; bottom: 0;
            background: #E8EDF3; opacity: .95;
        }
        > .container {
            position: relative;
            z-index: 2;
        }
    }

    .span-list {
        display: flex;
        @media (max-width: $breakpoint-lg) {
            flex-direction: column;
            gap: 20px;
        }
        justify-content: space-between;
        padding: 50px 0 0;

        span {
            i {
                color: $secondary;
                position: relative;
                margin-right: .5rem;

                &::after {
                    content: "";
                    background: #fff;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 1.75rem;
                    aspect-ratio: 1;
                    z-index: 0;
                    border-radius: 5px;
                }

                &::before {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }

    @media (max-width: $breakpoint-md) {
        h1 {
            font-size: 2rem !important;
        }
    }
}
