body.home section.service {
    margin-top: -140px !important;
}

section.services {
    margin-top: -200px;

    .slick-list {
        border-radius: $border-radius;
    }

    .slick-dots {
        width: auto !important;
        left: 50%; transform: translateX(-50%);
        li {
            width: 25px; height: 3px;
            button {
                width: 25px; height: 3px;
                &::before {
                    content: "";
                    width: 25px; height: 3px;
                    background: $primary;
                }
            }
        }
    }

    .service--block {
        width: 100%;
        height: 400px;
        @media (max-width: $breakpoint-sm) {
            height: 300px !important;
        }
        display: inline-flex !important;
        align-items: flex-end;
        justify-content: flex-start;
        overflow: hidden;
        position: relative;
        padding: 30px;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 0;
        }

        &::before {
            content: "";
            background: linear-gradient(90deg, rgba(0,0,0,0.8) 33%, rgba(250,250,250,0) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
            width: 50%;
            z-index: 1;
            opacity: .8;
        }

        .--content {
            position: relative;
            z-index: 2;
            @media (min-width: $breakpoint-lg) {
                max-width: 45%;
            }

            h3 {
                font-size: 1.75rem;
                font-weight: 600;
                margin: 0;
                color: #fff;
            }

            p {
                color: #f2f2f2;
                font-size: 1rem;
                padding-top: .5rem;
                margin: 0;
            }
        }
    }
}