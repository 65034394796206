header {
    .logo {
        padding: 0 !important;
        img {
            max-width: 170px;
        }
    }

    .menu {
        padding: 20px 15px;
        @media (max-width: $breakpoint-sm) {
            padding: 30px 10px 20px;
        }
        display: flex; justify-content: space-between; align-items: center;

        * {
            background: none !important;
        }

        a {
            font-family: "aglet-sans", sans-serif !important;
            font-weight: 300 !important;

            &.dtbtn {
                &:hover, &.active {
                    background: $primary !important;
                    color: #fff !important;
                }
            }
        }

        @media (max-width: $breakpoint-lg) {
            .donate-btn-header {
                a {
                    border: none !important;
                    padding: 10px 0 !important;
                    color: #232323 !important;
                    background: transparent !important;
                    border-radius: 0 !important;
                    font-size: 17px !important;
                }
            }
        }
    }

    #sticky-header:not(.sticky) {
        position: absolute; width: 100%;
    }

    @media (max-width: 959px) {
        .menu {
            .menu-mobile {
                position: relative;z-index: 9999;
                i {
                    font-size: 2.25rem;
                    color: $primary !important;
                }
            }
            &:has(.show-on-mobile) {
                .menu-mobile {
                    i::before {
                        content: '\f00d';
                    }
                }
            }
            .show-on-mobile {
                padding: 150px 15px 30px; width: 100%;
                position: absolute; top: 0; left: 0;
                background: rgba(255,255,255,.8); backdrop-filter: blur(1rem);
                box-shadow: $shadow-soft;
            }
            ul {
                li {
                    a {
                        padding: 10px 0;
                    }
                }
                .donate-btn-header {
                    display: block !important;
                    margin: 0 !important;
                }
            }
        }
    }
}

.menu ul li a {
    transition: all .2s ease-in-out;

    &:hover, &.active {
        color: $secondary !important;
    }
}