@use "sass:map";

// -- BREAKPOINTS -- //
$breakpoint-sm: 576px;
$breakpoint-md: 767px;
$breakpoint-lg: 991px;
$breakpoint-xl: 1200px;

$spacer: 1.25rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
);

// -- Fonts -- //
$font-family: "";
$fontSizing: (
        h1  4rem        1.6        800,
        h2  3rem        1.6        800,
        h3  2.5rem      1.6        800,
        h4  2rem        1.6        800,
        h5  1.5rem      1.6        800,
        h6  1rem        1.6        700,
        p   1rem        1.6        400,
);


// -- SHADOWS -- //
$shadow-soft: 0 0 25px rgba(54, 109, 193, 0.2);
$shadow-softer: 0 0 8px rgba(0, 0, 0, .1);

// -- BORDER RADIUS -- //
$border-radius: 10px;
$border-radius-soft: 5px;
$pill: 9999px;

// -- COLORS -- //
$primary: #005884;
$primary-darker: mix($primary, black, 80%);
$secondary: #f7941d;
$secondary-darker: mix($secondary, black, 80%);
$black: rgba(0, 0, 0, 1);
$lightgrey: rgb(153, 153, 153);
$white: rgba(255, 255, 255, 1);
$whiteaccent: rgb(242, 242, 242);
$alert: rgba(220, 53, 69, 1);
$succes: rgba(25, 135, 84, 1);

$text: #333333;