.btn--rounded {
    font-family: "aglet-sans", sans-serif;
    font-size: 1.25rem; color: $primary;
    display: inline-flex; align-items: center; gap: .5rem;
    transition: all .25s ease-out;
    position: relative;
    overflow: hidden !important;
    padding: 10px 16px 10px 0;
    //default button styles removal
    background: transparent !important;
    outline: none !important; border: none !important;
    text-decoration: none !important;
    &::before {
        content: "";
        position: absolute; left: 0; bottom: 10px; transform: translateX(-100%);
        height: 2px; width: calc(100% - 4.2rem);
        background: $primary; border-radius: 99px;
        transition: all .2s .45s ease-in-out;
    }
    i {
        font-size: 1.75rem;
        position: relative;
        &::after {
            content: ""; position: absolute; 
            left: 0; top: 50%; transform: translateY(-50%) skewX(0);
            height: 2.25rem; aspect-ratio: 1;
            opacity: 0;
            border-radius: 50%; background: transparent;
            border: 2px solid $primary;
            transition: all .25s ease-out;
        }
    }

    &:hover {
        &::before {
            transform: translateX(0);
        }
        i::after {
            aspect-ratio: 1;
            opacity: 1;
            transform: translateY(-50%) skewX(10deg);
        }
    }

    &.orange {
        color: $secondary;
        &::before {
            background: $secondary;
        }
        i::after {
            border-color: $secondary;
        }
    }
}