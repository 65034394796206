section.cta {
    img.hover-over-img {
        @media (max-width: $breakpoint-md) {
            display: none; visibility: hidden;
            opacity: 0;
        }
        position: absolute; left: -40px; top: -40px;
        filter: drop-shadow(0 0 4px rgba(30, 56, 143, 0.05));
        animation: moveLeftBounce 3.2s ease-in-out infinite;
        z-index: 2;
    }
}