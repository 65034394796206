section.text-image {

    .img-overlay {
        position: absolute; top: -30px; left: 30px;
        @media (max-width: $breakpoint-lg) {
            left: 15px;
        }
        z-index: 2;
        max-width: calc(100% - 45px);
        width: 280px;
        background: #fff; padding: 20px;
        border-radius: $border-radius;
        box-shadow: $shadow-soft;
        text-align: left;
        h2 {
            color: $primary;
            font-size: 2.25rem;
            position: relative;
            margin: 0 !important;
        }
    }
    ul {
        margin-left: 1.5rem !important;
        li {
            font-size: 1rem !important;
        }
    }
}