section.contact {
    background: #F7F9FD;
    padding: 200px 0 50px;

    .--box {
        width: 100%;
        display: flex; flex-direction: column; justify-content: space-between;
        padding: 45px;
        box-shadow: 0 0 15px rgba(61, 62, 185, 0.2);
        position: relative;
        .bird {
            position: absolute;
            right: -20px; top: -20px;
            animation: moveLeftBounce 3.2s ease-in-out infinite;
        }

        .__icon-box {
            padding: .25rem;
            background: mix($primary, white, 15%);
            border-radius: $border-radius;
            display: inline-flex;
            align-self: baseline;
        }
        img:not(.bird) {
            width: 5.5rem; aspect-ratio: 1;
            border-radius: $border-radius;
            display: inline-flex;
            align-self: baseline;
            object-fit: cover;
        }

        h4 {
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 5px !important;
            font-size: 1.1rem !important;

            a {
                color: #333 !important;

                &:hover {
                    color: $secondary !important;
                }
            }
        }
    }

    .contact--form__parent {
        label {
            color: $text !important;
            font-size: 1.25rem;

            i {
                color: $secondary !important;
                font-size: .75rem;
                position: relative; top: -5px;
            }
        }

        textarea {
            height: 250px; resize: none;
        }
    }
}