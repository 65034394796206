@each $type, $Fontsize, $Fontline-height, $Fontweight in $fontSizing {
  #{$type} {
    font-size: $Fontsize;
    line-height: $Fontline-height;
    font-weight: $Fontweight;
    //font-family: $font-family;
    @if(#{$type} == p) { letter-spacing: .0101rem; }
  }
  #{$type}.larger {
    font-size: $Fontsize * 1.5;
  }
  @media (max-width: $breakpoint-xl) {
    #{$type}        { font-size: $Fontsize * .92; }
    #{$type}.larger { font-size: $Fontsize * 1.1; }
  }
  @media (max-width: $breakpoint-md) {
    #{$type}        { font-size: $Fontsize * .85; }
    #{$type}.larger { font-size: $Fontsize * .975; }
  }
}

p.light {
  color: $lightgrey;
}