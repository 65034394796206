footer {
    padding: 40px 0 20px;
    @media (max-width: $breakpoint-md) {
        padding: 40px 0 0;
    }
    background-size: cover; background-position: top center;
    color: $text;
    position: relative;

    .footer-logo {
        h2 {
            span {
                font-family: "lemongrass-script", sans-serif;
                color: $secondary;
                font-weight: 300 !important; font-size: 2.65rem;
            }
        }
        p {
            margin: 1rem 0;
            font-size: .9rem
        }

        .icon {
            width: 2.5rem; aspect-ratio: 1;
            border-radius: 5px;
            background: #efefef !important;
            display: flex; align-items: center; justify-content: center;
            text-decoration: none;
            i {
                color: $text;
                font-size: 1.25rem;
            }

            &:hover {
                background: mix($primary, white, 15%) !important;
                i {
                    color: $primary;
                }
            }
        }
    }
    ul {
        margin: 0; padding: 0;
        list-style: none;
        li {
            margin: 0; padding: 0;
            overflow: hidden !important;
            a {
                text-decoration: none;
                color: $text;
                overflow: hidden;
                padding-bottom: 3px;
                position: relative;
                &::before {
                    content: '';
                    width: 100%; height: 1px;
                    background: $primary;
                    position: absolute; bottom: 0; left: 0;
                    transform: translateX(-100%);
                    transition: all .2s ease-in-out;
                }
                &:hover, &.active {
                    color: $secondary;
                    &::before {
                        transform: translateX(0);
                        background: $secondary;
                    }
                }
            }
        }
    }

    .footer-visual {
        position: absolute; right: 70px; top: 40px;
        filter: drop-shadow(0 0 4px rgba(30, 56, 143, 0.05));
        animation: moveLeftBounce 4s ease-in-out infinite;
        z-index: 2;

        @media (max-width: 1354px) {
            display: none; visibility: hidden;
        }
    }

    .made-by p {
        @media (min-width: calc($breakpoint-md) + 1px) {
            text-align: right;
        }
    }

    p.small a:hover {
        color: $secondary !important;
    }
}