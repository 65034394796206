// remove xxl
@media (min-width: 1200px)  {
    .container {
        max-width: 1142px;
    }
}
@media (min-width: 1400px)  {
    .container {
        max-width: 1142px;
    }
}