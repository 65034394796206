body.home {
    section.hero {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 950px;

        @media (max-width: 370px) {
            .single_slider {
                margin-top: -50px;
            }
        }

        .--hero-points {
            display: flex;
            flex-direction: column;
            gap: .25rem;
            margin-top: 2rem;

            span {
                position: relative;
                color: $text;
                font-size: 1.3rem;
                font-weight: 600;
                overflow: hidden !important;
                padding-bottom: 3px;
                align-self: baseline;

                i {
                    color: $secondary;
                }
            }
        }

        @media (max-width: $breakpoint-md) {
            height: 100svh !important;
            max-height: 750px;

            .slider16 {
                height: 100svh !important;
                max-height: 750px;

                .slider_text_inner {
                    text-align: left;
                    width: 100%;

                    h1 {
                        font-size: 3rem !important;
                    }
                }
            }
        }
    }
}