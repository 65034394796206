::selection { background: $primary; color: #fff; }
::-moz-selection { background: $primary; color: #fff; }
html::-webkit-scrollbar { width: 5px; }
html::-webkit-scrollbar-track { background-color: transparent; }
html::-webkit-scrollbar-thumb { background: $primary; }
ul { padding: 0; }

* {
    box-sizing: border-box;
}

html,body {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

img.styled {
    box-shadow: $shadow-soft;
    border-radius: $border-radius;
}

.slick-next {
     width: 40px; height: 40px;
     right: -45px;
    background: #fafafa !important;
    border-radius: 4px !important;
     &::before {
         content: '\f178';
         font-family: 'Font Awesome 6 pro';
         color: #333 !important;
     }
 }

.slick-prev {
    width: 40px; height: 40px;
    left: -45px;
    background: #fafafa !important;
    border-radius: 4px !important;
    &::before {
        content: '\f177';
        font-family: 'Font Awesome 6 pro';
        color: #333 !important;
    }
}


section.handler {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;

  @media (max-width: 991px) {
    background-position: center center;
  }
}

.error-help-block {
  color: red !important;
}