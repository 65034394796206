section.text-block {
    ul {
        padding-left: 2rem !important;
    }
    li { font-size: 1rem; }
    li + li {
        margin-top: .5rem;
    }
    i  { font-style: italic !important; }

    h3 {
        margin: 2rem 0 1.25rem;
    }
}