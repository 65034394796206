section.team {
    .d-grid {
        grid-template-columns: repeat(5, 1fr);
        @media (max-width: $breakpoint-lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: $breakpoint-md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 450px) {
            grid-template-columns: repeat(1, 1fr);
        }
        gap: 15px;
        .single-team {
            @media (max-width: 450px) {
                padding: 0 30px;
            }
            * {
                transition: all .3s ease-in-out;
            }
            .single-team__thumb {
                width: 100%; aspect-ratio: .9;
                border-radius: $border-radius;
                box-shadow: $shadow-soft;
                overflow: hidden;
                img {
                    object-fit: cover;
                    width: 100%; height: 100%
                }
            }
        }
    }
}